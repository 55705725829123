.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #71aac5;
}

.name {
  text-decoration: none;
  color: #406882;
  font-size: 60px;
  font-weight: bolder;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-shadow: 2px 2px 1.5px rgb(44 44 44);
  text-align: center;
}
