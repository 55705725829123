.site-links {
  display: flex;
  justify-content: space-evenly;
  width: 60%;
  list-style-type: none;
}

.full-profile {
  width: 35%;
  border: 4px solid #1a374d;
  border-bottom: 0;
  background-color: #406882;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.full-profile img {
  width: 70%;
  border: 11px solid rgb(0, 0, 0);
  margin-top: 45px;
}

.profile-content {
  width: 90%;
  margin-bottom: 20px;
  border: 2px solid #1a374d;
  padding: 5px;
  background-color: #5a93b9;
}

.profile-content h2 {
  text-align: center;
  font-size: 40px;
  color: #162937;
  opacity: 0.9;
  margin: 0px;
}

.profile-content p {
  text-align: center;
  font-size: 20px;
}

@media only screen and (max-width: 925px) {
  .full-profile {
    width: 100%;
  }

  .full-profile img {
    width: 50%;
    margin-bottom: 20px;
  }
}
