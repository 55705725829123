body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: rgb(129, 157, 182);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.main {
  display: flex;
}
/* 
div,
nav {
  border: 2px dashed greenyellow;
} */

ul {
  margin: 0;
}

@media only screen and (max-width: 925px) {
  .main {
    flex-direction: column;
  }
}
